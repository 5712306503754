export default class {
    constructor(param) {
        this.$el = {};
        Object.assign(this, param);
    }
    init(){
        if (app.env.isDesktop) {
            return;
        }

        this.$el
            .each((i, el)=>{
                const tel = $(el).text();

                $(el).replaceWith(() => {
                    $(el).replaceWith('<a href="tel:' + tel + '">' + tel + '</a>');
                });
            });
    }
}
