export default class {
    constructor(param) {
        this.$el = {};
        this.HIDE_DURATION = 600;
        this.SHOW_DURATION = 10000;
        this.SHOW_DURATION_BRIGHT = this.SHOW_DURATION;
        this.EASING = [0,.44,.37,1.01];
        this.SHOW_CONTROL_DURATION = 0;//矢印とインジゲーターが表示されるまでの時間
        Object.assign(this, param);
        this.$items = this.$el.find('.JS-kvSilder_item');
        this.$imgs = {};
    }
    init(){
        this._setEvents();
    }
    _setEvents(){
        let _beforeCurrent;

        this.$el
            .on('init', ()=>{})
            .on('beforeChange', (e, slick, currentSlide, nextSlide) => {
                _beforeCurrent = currentSlide;
                this._hideSlide();
            })
            .on('afterChange', (e, slick, currentSlide) => {
                if (_beforeCurrent !== currentSlide) {
                    this._showSlideTo(currentSlide);
                    this.$el.slick('slickPause');
                    this.$el.slick('slickPlay');
                }
            });

        app
            .on('INTRO_ENDED', ()=>{
                this._start();
            });

        this._setup();
    }
    _start(){
        this._showSlideTo(0);
        this.$el.slick('slickPlay');

        setTimeout(()=>{
            this.$el.find('.slick-arrow, .slick-dots').addClass('-show');
        },　this.SHOW_CONTROL_DURATION);
    }
    _setup(){
        this.$items
            .each((i, el)=>{
                let $img = $('<p class="JS-kvSilder_img" />');
                $img.css('background-image', 'url(' + $(el).data('img-url') + ')');
                $(el).append($img);
            });

        this.$imgs = this.$el.find('.JS-kvSilder_img');

        this.$el.slick({
            arrows: true,
            fade: true,
            speed: this.HIDE_DURATION,
            dots: true,
            autoplay: true,
            autoplaySpeed: 6000,
            pauseOnHover: false,
            pauseOnFocus: false,
            prevArrow: '<p class="slick-prev slick-arrow"><i class="slick-prev_icon m-icon-arrow1-l" /></p>',
            nextArrow: '<p class="slick-next slick-arrow"><i class="slick-next_icon m-icon-arrow1-r" /></p>',
            customPaging : function(slider, i) {
                const thumb = $(slider.$slides[i]).data('thumb');
                return '<a></a>';
            }
        });

        this.$el.slick('slickPause');
    }
    _hideSlide(){
        this.$imgs
            .velocity('stop')
            .velocity(
                { opacity: 0, scale: 1.0 },
                { duration: this.HIDE_DURATION }
            );
    }
    _showSlideTo(index){

        if(this.$el.hasClass('JS-kvSilder_bright')){
            this.SHOW_DURATION_BRIGHT = 2000;
        }

        this.$imgs
            .eq(index)
            .velocity('stop')
            .velocity(
                { opacity: [1,0]},
                { duration: this.SHOW_DURATION_BRIGHT, easing: this.EASING, queue: false }
            )
            .velocity(
                { scale: [1, 1.08] },
                { duration: this.SHOW_DURATION, easing: this.EASING, queue: false }
            );
    }
}
