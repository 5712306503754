import KvSlider from './KvSlider';

export default class {
    constructor(param) {
        this.$el = {};
        Object.assign(this, param);
        this.$title = this.$el.find('.JS-kvSilder_title');
        this.slider = new KvSlider({
            $el: this.$el.find('.JS-kvSilder_carousel'),
            SHOW_CONTROL_DURATION: 2500
        });
    }
    init(){
        this._setEvents();
    }
    _setEvents(){
        this.slider.init();

        app
            .on('INTRO_ENDED', ()=>{
                this._show();
            });
    }
    _show(){
        this.$title.addClass('-show');
    }
}
