export default class {
    constructor(param) {
        this.zoom = 16;
        this.elId = 'js-map_canvas';
        this.pinW = 77;
        this.pinH = 58;
        this.name = 'pipal';
        this.enableScrollWheel = false;
        this.enableDrag = true;
        this.style = [
            {
                "featureType": "all",
                "elementType": "all",
                "stylers": [
                    {
                        "saturation": "-5"
                    },
                    {
                        "visibility": "simplified"
                    }
                ]
            },
            {
                "featureType": "all",
                "elementType": "labels",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "administrative.province",
                "elementType": "all",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "landscape",
                "elementType": "all",
                "stylers": [
                    {
                        "saturation": "8"
                    },
                    {
                        "lightness": "3"
                    },
                    {
                        "visibility": "on"
                    },
                    {
                        "hue": "#ff8900"
                    }
                ]
            },
            {
                "featureType": "poi",
                "elementType": "all",
                "stylers": [
                    {
                        "saturation": "-100"
                    },
                    {
                        "lightness": 51
                    },
                    {
                        "visibility": "simplified"
                    }
                ]
            },
            {
                "featureType": "poi",
                "elementType": "labels.text",
                "stylers": [
                    {
                        "saturation": "7"
                    },
                    {
                        "lightness": "-54"
                    },
                    {
                        "color": "#674d41"
                    },
                    {
                        "visibility": "simplified"
                    }
                ]
            },
            {
                "featureType": "road.highway",
                "elementType": "all",
                "stylers": [
                    {
                        "saturation": -100
                    },
                    {
                        "visibility": "simplified"
                    }
                ]
            },
            {
                "featureType": "road.arterial",
                "elementType": "all",
                "stylers": [
                    {
                        "saturation": -100
                    },
                    {
                        "lightness": "14"
                    },
                    {
                        "visibility": "on"
                    }
                ]
            },
            {
                "featureType": "road.local",
                "elementType": "all",
                "stylers": [
                    {
                        "saturation": -100
                    },
                    {
                        "lightness": "22"
                    },
                    {
                        "visibility": "on"
                    }
                ]
            },
            {
                "featureType": "transit",
                "elementType": "all",
                "stylers": [
                    {
                        "saturation": -100
                    },
                    {
                        "visibility": "simplified"
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "geometry",
                "stylers": [
                    {
                        "hue": "#ffff00"
                    },
                    {
                        "lightness": -25
                    },
                    {
                        "saturation": -97
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "labels",
                "stylers": [
                    {
                        "visibility": "on"
                    },
                    {
                        "lightness": -25
                    },
                    {
                        "saturation": -100
                    }
                ]
            }
        ];
        this.marker = [];
        this.pinData = [
            {
                name: 'pipal(ピパル 渋谷宇田川)',
                lat: 35.662948,
                lng: 139.695565,
                pinSrc: '/assets/img/top/access_pin.png'
            },
            {
                name: 'pipal deux(ピパル ドゥ)',
                lat: 35.660570,
                lng: 139.705174,
                pinSrc: '/assets/img/top/access_pin-deux.png'
            }
        ];

        Object.assign(this, param);
    }
    init(){
        if (document.getElementById(this.elId) == null) {
            return;
        }

        this._setEvents();
    }
    _setEvents(){
        app.window
            .on('LOAD', ()=>{
                this._setMap();
            })
            .on('RESIZE', ()=>{
                this._update();
            });
    }
    _update(){
        var center = this.map.getCenter();
        google.maps.event.trigger(this.map, "resize");
        this.map.setCenter(center);

        if (app.breakPoint < $(window).width()) {
            this.map.setZoom(this.zoom);
        }else{
            this.map.setZoom(this.zoom - 2);
        }
    }
    _setMap(){
        this._renderMap();
        this._setPin();
        this._setStyle();
        this._getCenterLatLng();
        this._update();
    }
    _renderMap(){
        let myOptions = {
            zoom: this.zoom,
            center: new google.maps.LatLng(this._getCenterLatLng().lat, this._getCenterLatLng().lng),
            scrollwheel: this.enableScrollWheel,
            draggable: this.enableDrag,
            mapTypeControlOptions: {
                mapTypeIds: ['sample', google.maps.MapTypeId.ROADMAP]
            }
        };

        this.map = new google.maps.Map(document.getElementById(this.elId), myOptions);
    }
    _setPin(){
        for (var i = 0; i < this.pinData.length; i++) {
            let pinData = this.pinData[i];

            let markerLatLng = new google.maps.LatLng({lat: pinData['lat'], lng: pinData['lng']});

            let icon = new google.maps.MarkerImage(
                pinData['pinSrc'],
                new google.maps.Size(this.pinW, this.pinH),
                new google.maps.Point(0, 0)
            );

            icon.scaledSize = new google.maps.Size(this.pinW, this.pinH);

            this.marker[i] = new google.maps.Marker({
                position: markerLatLng,
                map: this.map,
                icon: icon
            });
        }
    }
    _setStyle(){
        let sampleType = new google.maps.StyledMapType(this.style, { name: this.name });

        this.map.mapTypes.set('sample', sampleType);
        this.map.setMapTypeId('sample');
    }
    /*
        複数ある住所の中心を計算
     */
    _getCenterLatLng(){
        let lat = [];
        let lng = [];

        for (var i = 0; i < this.pinData.length; i++) {
            let pinData = this.pinData[i];

            lat.push(pinData['lat']);
            lng.push(pinData['lng']);
        }

        let dif_lat = _.max(lat) - _.min(lat);
        let dif_lng = _.max(lng) - _.min(lng);


        return {
            lat: (dif_lat / 2) + _.min(lat),
            lng: (dif_lng / 2) + _.min(lng)
        }
    }
}
