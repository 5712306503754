export default class {
    constructor(param) {
        this.$el = {};
        Object.assign(this, param);
        this.$spMenu = $('#spMenu');
        this.$btnOpen = $('.u-header_spMenu.-open');
        this.$btnClose = $('.u-header_spMenu.-close');
    }
    init(){
        this._setEvents();
    }
    _setEvents(){

        this.$btnOpen
            .on('click', ()=>{
                this.openModal();
                this.showCloseBtn();
                return false;
            });

        this.$btnClose
            .on('click', ()=>{
                $('.close-modal').trigger('click');
                return false;
            });

        this.$spMenu
            .on($.modal.BEFORE_CLOSE, ()=> {
                this.hideCloseBtn();
            });
    }
    openModal(){
        this.$spMenu.modal({
            fadeDuration: 200
        });
    }
    showCloseBtn(){
        this.$btnClose.css({display:'block'})
        this.$btnOpen.css({display:'none'})
    }
    hideCloseBtn(){
        this.$btnClose.css({display:'none'})
        this.$btnOpen.css({display:'block'})
    }
}
