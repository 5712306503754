export default class {
    constructor(param) {
        this.$el = {};
        Object.assign(this, param);
    }
    init(){
        this._setEvents();
    }
    _setEvents(){
    }
}
