export default class {
    constructor(param) {
        this.$el = {};
        Object.assign(this, param);
    }
    init(){
        this._setEvents();
    }
    _setEvents(){
        $(window)
            .on('load', ()=>{
                this._hide();
            });
    }
    _hide(){
        this.$el.velocity(
            { opacity: 0 },
            { display: 'none', duration: 1000, delay:0, complete: ()=>{
                app.emit('INTRO_ENDED');
            } }
        )
    }
}
