export default class {
    constructor(param) {
        this.$el = {};
        Object.assign(this, param);
        this.position;
        this.targetConts;
        this.targetHref;
        this.time = '.6';
    }
    init(){
        this._setEvents();
    }
    _setEvents(){
        this.$el.on('click',(e) => {
            this.targetHref = $(e.currentTarget).attr('href')

            if (this.targetHref === 'js-top') {
                this.position = 0;

                this._scrollToTarget();
            } else {
                this.targetConts = $(this.targetHref == "#" || this.targetHref == "" ? 'html' : this.targetHref);
                this.position = this.targetConts.offset().top;

                this._scrollToTarget();
            }
            return false;
        });
    }
    _scrollToTarget(){
        TweenMax.to('body,html', this.time, {
            ease: Power3.easeOut,
            scrollTop:this.position
        });
    }
}
