export default class {
    constructor(param) {
        this.$el = {};
        Object.assign(this, param);
        this.$subMenuWrap = this.$el.find('.nav_subMenuWrap');
        this.$subMenu = this.$el.find('.nav_list.-sub');
        this.$currentItem = this.$subMenu.find('.nav_list_item.is-current');
        this.$Item = this.$subMenu.find('.nav_list_item');
        this.spacing = 25;
        this.bleakPoint = 768;
        this.wrapWidth = 0;
        this.widthFlag = '';
    }
    init(){

        //サブメニューがない時、発動しない
        if(this.$subMenu.length === 0){
            return;
        }

        this._setEvents();

    }
    _setEvents(){

        $(window)
            .on('load resize',()=>{
                this._resizeEvent();
            });

    }
    _resizeEvent(){

        if($(window).width() < this.bleakPoint && this.widthFlag != 'sp') {

            this.widthFlag = 'sp';
            this._setSP();

        } else if($(window).width() >= this.bleakPoint && this.widthFlag != 'pc') {

            this.widthFlag = 'pc';
            this._setPC();

        }

    }
    _setSP(){

        this.$Item.find('.nav_list_link').removeClass('-transition');

        this.$subMenu
            .find('.nav_list_item')
            .each((i, el) => {
                $(el).css({
                    'padding-right' : this.spacing
                });
                this.wrapWidth += ($(el).width() + this.spacing);

                if( i === (this.$subMenu.find('.nav_list_item').length - 1) ){
                    this.$subMenu
                        .css({
                            'width' : this.wrapWidth
                        })
            	}
            })

        this._currentSet();

    }
    _setPC(){

        this.$Item.find('.nav_list_link').addClass('-transition');

        this.$subMenu
            .css({
                'width' : 'auto'
            })

        this.wrapWidth = 0;

    }
    _currentSet(){

        //カレントがない時、発動しない
        if(this.$currentItem.length === 0){
            return;
        }

        this.$subMenuWrap
            .scrollLeft(
                this.$currentItem.offset().left - ($(window).width() / 2) + (this.$currentItem.width() / 2)
            );

    }
}
