import Cover from './_modules/Cover';
import Footer from './_modules/Footer';
import Tel from './_modules/Tel';
import MainVisual from './_modules/MainVisual';
import KvSlider from './_modules/KvSlider';
import AccessMap from './_modules/AccessMap';
import SpMenu from './_modules/SpMenu';
import ShopNav from './_modules/ShopNav';
import SmoothScroll from './_modules/SmoothScroll';

export default class {
    constructor() {
        this.cover = new Cover({
            $el: $('.u-cover')
        });

        this.footer = new Footer({
            $el: $('.u-footer')
        });

        this.tel = new Tel({
            $el: $('.js-tel')
        });

        this.KvSlider = KvSlider;

        this.mainVisual = new MainVisual({
            $el: $('.js-mainVisual')
        });

        this.accessMap = new AccessMap({
            enableDrag: ( app.env.isMobile || app.env.isTablet ) ? false : true
        });

        this.spMenu = new SpMenu({
            $el: $('.m-spNavInfo')
        });

        this.shopNav = new ShopNav({
            $el: $('.page-shopCommon .nav')
        });

        this.smoothScroll = new SmoothScroll({
            $el: $('.js-scroll')
        });
    }
    init(){
        this.tel.init();
        this.footer.init();
        this.cover.init();
        this.mainVisual.init();
        this.accessMap.init();
        this.spMenu.init();
        this.shopNav.init();
        this.smoothScroll.init();
    }
}
