import Common from '../page-common/page';
import KvSlider from '../page-common/_modules/KvSlider';

class Page extends Common{
    constructor() {
        super();

        /*
         *  店舗によってピンを出し分け
         *  class名で指定
        */
        if ($('#js-map_canvas').hasClass('pipal')) {
            this.accessMap.pinData = [
                {
                    name: 'pipal(ピパル 渋谷宇田川)',
                    lat: 35.662948,
                    lng: 139.695565,
                    pinSrc: '/assets/img/top/access_pin.png'
                }
            ];
        } else if ($('#js-map_canvas').hasClass('deux')) {
            this.accessMap.pinData = [
                {
                    name: 'pipal deux(ピパル ドゥ)',
                    lat: 35.660570,
                    lng: 139.705174,
                    pinSrc: '/assets/img/top/access_pin-deux.png'
                }
            ];
        }

        this.accessMap.zoom = 16;

        this.menuSlider = new KvSlider({
            $el: $('.js-menuKv')
        });
    }
    init(){
        super.init();

        this.menuSlider.init();

        //NEWS
        $('.news .js-matchHeight').matchHeight();
    }
}

app.page = Page;
app.start();
